var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-dropdown",
        {
          staticClass: "context-menu",
          attrs: {
            "toggle-class": "dot-button",
            "no-caret": "",
            dropup: "",
            "no-flip": "",
            right: ""
          },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function() {
                return [
                  _c("b-icon", {
                    attrs: {
                      icon: "three-dots-vertical",
                      "aria-hidden": "true",
                      scale: ".8"
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.userObject.status === _vm.$t("teamManagement.invited")
            ? _c(
                "b-dropdown-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.sendInvitationReminder(
                        _vm.userObject.email,
                        _vm.userObject.name
                      )
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("dropdowns.invitation")) + " ")]
              )
            : _vm._e(),
          _c(
            "b-dropdown-item",
            {
              on: {
                click: function($event) {
                  _vm.showEditUserRoleModal = true
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("dropdowns.editUser")) + " ")]
          ),
          _c(
            "b-dropdown-item",
            {
              on: {
                click: function($event) {
                  _vm.showDeleteUserRoleModal = true
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("dropdowns.remove")) + " ")]
          )
        ],
        1
      ),
      _vm.showEditUserRoleModal
        ? _c("modal-edit-user-role", {
            staticClass: "d-inline-block",
            attrs: { "user-object": _vm.userObject, "team-id": _vm.teamIndex },
            on: {
              editUser: _vm.editUser,
              closeModal: function($event) {
                _vm.showEditUserRoleModal = false
              }
            }
          })
        : _vm._e(),
      _vm.showDeleteUserRoleModal
        ? _c("modal-delete-user", {
            staticClass: "d-inline-block",
            attrs: {
              userUserId: _vm.userObject.userId,
              "team-id": _vm.team.teamId
            },
            on: {
              deleteUser: _vm.deleteUser,
              closeModal: function($event) {
                _vm.showDeleteUserRoleModal = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }