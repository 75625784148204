<template>
  <div>
    <b-dropdown
      class="context-menu"
      toggle-class="dot-button"
      no-caret
      dropup
      no-flip
      right
    >
      <template #button-content>
        <b-icon icon="three-dots-vertical" aria-hidden="true" scale=".8"></b-icon>
      </template>
      <b-dropdown-item
        v-if="userObject.status === $t('teamManagement.invited')"
        @click="sendInvitationReminder(userObject.email, userObject.name)">
        {{ $t('dropdowns.invitation') }}
      </b-dropdown-item>
      <b-dropdown-item @click="showEditUserRoleModal = true">
        {{ $t('dropdowns.editUser') }}
      </b-dropdown-item>
      <b-dropdown-item @click="showDeleteUserRoleModal = true">
        {{ $t('dropdowns.remove') }}
      </b-dropdown-item>
    </b-dropdown>
    <modal-edit-user-role
      v-if="showEditUserRoleModal"
      class="d-inline-block"
      :user-object="userObject"
      :team-id="teamIndex"
      @editUser="editUser"
      @closeModal="showEditUserRoleModal = false"
    ></modal-edit-user-role>
    <modal-delete-user
      v-if="showDeleteUserRoleModal"
      class="d-inline-block"
      :userUserId="userObject.userId"
      :team-id="team.teamId"
      @deleteUser="deleteUser"
      @closeModal="showDeleteUserRoleModal = false"
    ></modal-delete-user>
  </div>
</template>

<script>
export default {
  name: 'UserManagementDropdown',
  components: {
    ModalEditUserRole: () => import('@/components/modals/team/ModalEditTeamUserRole.vue'),
    ModalDeleteUser: () => import('@/components/modals/ModalDeleteUser.vue'),
  },
  props: [
    'team',
    'teamIndex',
    'userObject',
  ],
  data: () => ({
    showEditUserRoleModal: false,
    showDeleteUserRoleModal: false,
  }),
  methods: {
    editUser() {
      this.showEditUserRoleModal = false;
      this.$emit('editUser');
    },
    deleteUser(userId) {
      this.showDeleteUserRoleModal = false;
      this.$emit('deleteUser', userId);
    },
    sendInvitationReminder(email, name) {
      this.$emit('sendInvitationReminder', email, name);
    },
  },
};
</script>

<style scoped>

</style>
